<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <span v-if="isImpersonating">
        <v-badge color="primary" overlap>
          <template v-slot:badge>
            <v-icon x-small>mdi-lock</v-icon>
          </template>
          <v-btn small v-bind="attrs" v-on="on" color="info" class="ml-2">
            {{ name }} <v-icon>mdi-menu-down</v-icon>
            <v-icon v-if="missingProfileInfo">mdi-account-alert</v-icon>
          </v-btn>
        </v-badge>
      </span>
      <span v-else>
        <v-btn small v-bind="attrs" v-on="on" color="info" class="ml-2">
          {{ name }} <v-icon>mdi-menu-down</v-icon>
          <v-icon v-if="missingProfileInfo">mdi-account-alert</v-icon>
        </v-btn>
      </span>
    </template>
    <v-list dense>
      <v-list-item-group color="primary">
        <v-list-item three-line disabled>
          <v-list-item-content>
            <v-list-item-title v-text="organization"></v-list-item-title>
            <v-list-item-subtitle v-text="name"></v-list-item-subtitle>
            <v-list-item-subtitle
              v-text="
                selectedParticipant && selectedParticipant.participantType
                  ? selectedParticipant.participantType.name
                  : ''
              "
            ></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'profile' }">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $i18n.translate("Profile") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'login' }">
          <v-list-item-icon>
            <v-icon>mdi-login</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $i18n.translate("Logout") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider v-if="isImpersonating"></v-divider>
        <v-subheader v-if="isImpersonating">
          {{ impersonationName }}
        </v-subheader>
        <v-list-item v-if="isImpersonating" @click="onStopImpersonate">
          <v-list-item-icon>
            <v-icon>mdi-login</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $i18n.translate("Stop Impersonating") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UserDropdown",
  methods: {
    onStopImpersonate() {
      return this.$auth.stopImpersonation().then(() => {
        return this.$router.push({ name: "dashboard" }).catch(() => {});
      });
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "impersonationParticipant", "missingProfileInfo"]),
    name() {
      return this.selectedParticipant ? this.selectedParticipant.fullName : "";
    },
    organization() {
      let organization = null;
      if (this.selectedParticipant && this.selectedParticipant.organization) {
        organization = this.selectedParticipant.organization.name;
      }
      return organization;
    },
    impersonationName() {
      return this.impersonationParticipant ? this.impersonationParticipant.fullName : "";
    },
    isImpersonating() {
      return this.impersonationParticipant && this.impersonationParticipant.id;
    }
  }
};
</script>
