<template>
  <v-breadcrumbs v-bind="$attrs" v-on="$listeners" :items="breadcrumbs" active-class="breadcrumb-active">
    <template v-slot:divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>
  </v-breadcrumbs>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Breadcrumbs",
  computed: {
    ...mapGetters(["breadcrumbs"])
  }
};
</script>
