var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tabs',{ref:"tabs",attrs:{"centered":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.filteredItems),function(rootItem,i){return [(_vm.hasAccess(rootItem) && rootItem.children)?_c('v-tab',{key:i,on:{"click":function($event){_vm.showMenu[i] = !_vm.showMenu[i]}}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({on:{"click":function($event){_vm.showMenu[i] = !_vm.showMenu[i]}}},'span',attrs,false),on),[_vm._v(_vm._s(rootItem.text)),_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}],null,true),model:{value:(_vm.showMenu[i]),callback:function ($$v) {_vm.$set(_vm.showMenu, i, $$v)},expression:"showMenu[i]"}},[_c('v-list',{attrs:{"dense":"","flat":""}},[_c('v-list-item-group',{attrs:{"active-class":""}},[_vm._l((rootItem.children),function(item,j){return [_c('v-menu',{key:j,attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.hasAccess(item))?_c('v-list-item',{key:j,attrs:{"link":"","to":!item.children ? item.to : null},on:{"click":function($event){_vm.chooseTab(i);
                      _vm.hideAllMenus(!item.children);}}},[_c('v-list-item-content',_vm._g(_vm._b({on:{"click":function($event){return _vm.hideAllMenus(!item.children)}}},'v-list-item-content',attrs,false),on),[_c('v-list-item-title',{attrs:{"dense":""},on:{"click":function($event){_vm.chooseTab(i);
                          _vm.hideAllMenus(!item.children);}}},[_c('span',[_vm._v(_vm._s(item.text))])])],1),(item.children)?_c('v-list-item-icon',_vm._g(_vm._b({},'v-list-item-icon',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu-right")])],1):_vm._e()],1):_vm._e()]}}],null,true)},[(item.children)?_c('v-list',{attrs:{"dense":"","flat":""}},[_c('v-list-item-group',{attrs:{"active-class":""}},_vm._l((item.children),function(innerItem){return _c('v-list-item',{key:innerItem.text,attrs:{"hidelink":"","to":innerItem.to},on:{"click":function($event){return _vm.hideAllMenus(true)}}},[_c('v-list-item-content',{on:{"click":function($event){return _vm.hideAllMenus(true)}}},[_c('v-list-item-title',{attrs:{"dense":""},on:{"click":function($event){return _vm.hideAllMenus(true)}}},[_c('span',[_vm._v(_vm._s(innerItem.text))])])],1)],1)}),1)],1):_vm._e()],1)]})],2)],1)],1)],1):(_vm.hasAccess(rootItem))?_c('v-tab',{key:i,attrs:{"to":rootItem.to},on:{"click":function($event){_vm.showMenu[i] = !_vm.showMenu[i]}}},[_c('span',[_vm._v(_vm._s(rootItem.text))])]):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }