<template>
  <v-app>
    <SonyEnvironmentSystemBar />
    <v-app-bar app dark>
      <v-btn v-if="isGappInternalUser" @click="navigateToPortal()">
        Portal <v-icon right>mdi-account-cog</v-icon>
      </v-btn>
      <SelectedProgram />
      <SelectedProgramDropdown class="pr-15" />
      <v-spacer />

      <country-flag v-if="this.selectedCountry.name === 'USA'" country="usa" size="normal" />
      <country-flag v-if="this.selectedCountry.name === 'CAN'" country="can" size="normal" />

      <LocaleDropdown :routeToDashboard="true" />
      <v-btn @click="contactUsDialog = true" class="ml-2" color="secondary" small>Contact us</v-btn>
      <UserDropdown />
      <template v-slot:extension>
        <TopMenu />
      </template>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main class="login-bg">
      <Breadcrumbs />
      <!-- Provides the application the proper gutter -->
      <v-container>
        <router-view :key="$route.fullPath" />
      </v-container>
    </v-main>
    <v-footer absolute>
      <Footer />
    </v-footer>

    <v-dialog v-model="contactUsDialog" max-width="500">
      <v-card class="elevation-12">
        <v-toolbar dark color="secondary">
          <v-toolbar-title>
            <span> Contact Us </span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-8">
          <p>Our customer care team is available between 8:30am and 5:00pm (ct) Monday through Friday to assist.</p>

          <p v-if="selectedProgram.programEmail">
            <a :href="'mailto:' + selectedProgram.programEmail">{{ selectedProgram.programEmail }}</a>
          </p>
          <p v-if="selectedProgram.programPhone">
            <a :href="'tel:' + selectedProgram.programPhone">{{ selectedProgram.programPhone }}</a>
          </p>
        </v-card-text>

        <v-card-actions align="center">
          <v-spacer />
          <v-btn color="secondary" @click="contactUsDialog = false">Close</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import Footer from "@/components/Footer.vue";
import TopMenu from "@/components/TopMenu.vue";
import UserDropdown from "@/components/UserDropdown.vue";
import SelectedProgram from "@/components/SelectedProgram.vue";
import SonyEnvironmentSystemBar from "@/views/SonyEnvironmentSystemBar.vue";
import SelectedProgramDropdown from "@/gapp-components/components/fields/SelectedProgramDropdown";
import LocaleDropdown from "@/gapp-components/components/fields/LocaleDropdown";
import Breadcrumbs from "@/gapp-components/components/display/Breadcrumbs";
import CountryFlag from "vue-country-flag";
import { mapGetters } from "vuex";

export default {
  name: "LayoutAuthenticated",
  components: {
    Breadcrumbs,
    LocaleDropdown,
    SelectedProgramDropdown,
    CountryFlag,
    SonyEnvironmentSystemBar,
    SelectedProgram,
    UserDropdown,
    TopMenu,
    Footer
  },
  data: () => ({
    contactUsDialog: false
  }),
  methods: {
    navigateToPortal() {
      this.$portal.navigateProgram(this.selectedProgram.id, true);
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram", "selectedCountry"]),
    isGappInternalUser() {
      return this.$privilege.isGappInternalUser();
    }
  }
};
</script>
