var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isImpersonating)?_c('span',[_c('v-badge',{attrs:{"color":"primary","overlap":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-lock")])]},proxy:true}],null,true)},[_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":"","color":"info"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.name)+" "),_c('v-icon',[_vm._v("mdi-menu-down")]),(_vm.missingProfileInfo)?_c('v-icon',[_vm._v("mdi-account-alert")]):_vm._e()],1)],1)],1):_c('span',[_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":"","color":"info"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.name)+" "),_c('v-icon',[_vm._v("mdi-menu-down")]),(_vm.missingProfileInfo)?_c('v-icon',[_vm._v("mdi-account-alert")]):_vm._e()],1)],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',{attrs:{"three-line":"","disabled":""}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.organization)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.name)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(
              _vm.selectedParticipant && _vm.selectedParticipant.participantType
                ? _vm.selectedParticipant.participantType.name
                : ''
            )}})],1)],1),_c('v-list-item',{attrs:{"to":{ name: 'profile' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$i18n.translate("Profile")))])],1)],1),_c('v-list-item',{attrs:{"to":{ name: 'login' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-login")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$i18n.translate("Logout")))])],1)],1),(_vm.isImpersonating)?_c('v-divider'):_vm._e(),(_vm.isImpersonating)?_c('v-subheader',[_vm._v(" "+_vm._s(_vm.impersonationName)+" ")]):_vm._e(),(_vm.isImpersonating)?_c('v-list-item',{on:{"click":_vm.onStopImpersonate}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-login")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$i18n.translate("Stop Impersonating")))])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }